import React, { useEffect } from "react";
import useQuery from "../../services/useQuery";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const appURL = process.env.REACT_APP_BASE_URL;

const ResetPasswordPage = () => {
  let query = useQuery();
  const [requestStatus, setRequestStatus] = React.useState("initial");
  const emailInput = React.useRef<HTMLInputElement>(null);
  const passwordInput = React.useRef<HTMLInputElement>(null);
  const submitEnabled =
    requestStatus === "waitingUser" || requestStatus === "failed";

  useEffect(() => {
    const token = query.get("token");
    console.log("TOKEN IS ", token);
    if (token) {
      setRequestStatus("waitingUser");
    }
  }, [query]);

  const handleSubmit = (event: any) => {
    if (!submitEnabled) {
      return;
    }
    event.preventDefault();
    const token = query.get("token");
    setRequestStatus("submiting");

    fetch(appURL + "/users/reset_password", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        reset_password_token: token,
        password: passwordInput.current!.value
      })
    })
      .then(response => {
        console.log(response);
        if (response.ok) {
          setRequestStatus("succeeded");
        } else {
          setRequestStatus("failed");
        }
      })
      .catch(error => {
        setRequestStatus("failed");
      });
  };

  return (
    <div>
      <Header />
      <div>
        <h2>Reset Password</h2>

        <form onSubmit={handleSubmit}>
          <p>
            <label>
              Email: <input ref={emailInput} type="email" name="email" autoComplete="email" />
            </label>
          </p>
          <p>
            <label>
              Password:{" "}
              <input ref={passwordInput} type="password" name="password" autoComplete="new-password" />
            </label>
          </p>
          <button disabled={!submitEnabled}>Reset Password</button>
        </form>
        <p>{requestStatus}</p>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPasswordPage;
