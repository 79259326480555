import React, { useEffect } from "react";
import {
  useLocation
} from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const appURL = process.env.REACT_APP_BASE_URL;

const VerifyEmailPage = () => {
  const location = useLocation();
  const [requestStatus, setRequestStatus] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      setRequestStatus("verifying");
      fetch(appURL + "users/email_confirmation", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ token: token })
      })
        .then(response => {
          if (response.ok) {
            setRequestStatus("Verified");
          } else {
            setRequestStatus("Verification Failed");
          }
          response
            .json()
            .then(data => {
              if(data.errors){
                setErrorMessage(Object.keys(data.errors).map((key)=> data.errors[key].join("\n")).join("\n"));
              }
            })
            .catch(err => {
            });
        })
        .catch(error => {
          setRequestStatus("Verification Failed");
        });
    }
  }, [location]);

  return (
    <div>
      <Header />
      <div>
        <h2>Email Verification</h2>
        <p>{requestStatus}</p>
        <p>{errorMessage}</p>
      </div>
      <Footer />
    </div>
  );
};

export default VerifyEmailPage;
