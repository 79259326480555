import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import HomePage from './pages/HomePage';
import VerifyEmailPage from "./pages/users/VerifyEmailPage";
import ResetPasswordPage from "./pages/users/ResetPasswordPage";
import DownloadGuruPage from "./pages/guru/DownloadGuruPage";

export default function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/users/resetPassword">
                        <ResetPasswordPage/>
                    </Route>
                    <Route path="/users/verifyEmail">
                        <VerifyEmailPage />
                    </Route>
                    <Route path="/guru" >
                        <DownloadGuruPage />
                    </Route>
                    <Route path="/gurustaging" >
                        <DownloadGuruPage />
                    </Route>
                    <Route path="/gurudevelopment" >
                        <DownloadGuruPage />
                    </Route>
                    <Route path="/">
                        <HomePage/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}
