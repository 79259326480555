import React from "react";
import GuruHero from "./GuruHero";
import Features from "../../components/Features";
import Footer from "../../components/Footer";
import Stats from "../../components/Stats";
import Faq from "../../components/Faq";
import Cta from "../../components/Cta";

const DownloadGuruPage = () => {
  return (
    <div>
        <GuruHero/>
        <Features/>
        <Stats/>
        <Faq/>
        <Cta/>
        <Footer/>
    </div>
);
}

export default DownloadGuruPage;
