import React from "react";
import Hero from "../components/Hero";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Stats from "../components/Stats";
import Faq from "../components/Faq";
import Cta from "../components/Cta";

const HomePage = () => {
  return (
    <div>
        <Hero/>
        <Features/>
        <Stats/>
        <Faq/>
        <Cta/>
        <Footer/>
    </div>
);
}

export default HomePage;
